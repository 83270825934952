body {
  font-family: "proxima-nova", sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  display: block;
  margin: 8px 0 0 0;
}

a {
  color: #333;
  text-decoration: none;
}

p {
  margin: 0 0 10px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1,
h2,
h3,
b,
strong {
  font-weight: 700;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

p {
  margin: 0 0 10px;
}

hr {
  margin-top: 50px;
  margin-bottom: 40px;
  border: 0;
  border-top: 1px solid #eee;
}

.content {
  max-width: 1170px;
  margin: 30px auto 0;
  padding: 0 1px;
}

.wrap {
  padding: 20px;
}

.side-wrap {
  padding: 0 20px;
}

.side-wrap span {
  margin-bottom: 10px;
  display: block;
}

.row {
  margin: 10px;
  text-align: center;
}

.logo {
  margin: 0 auto;
  max-width: 69px;
}

img {
  vertical-align: middle;
  border: 0;
}

/* Accordion
=================================*/

div#accordion {
  margin: 0 auto 40px;
  border-radius: 12px;
  position: relative;
  z-index: 10;
  top: 20px;
}

.accordion * {
  font-family: "proxima-nova", sans-serif;
  box-sizing: border-box;
  outline: 0;
}

.accordion {
  background-color: #ffffff;
  border: solid 1px #e9e9e9;
}

.accordion-content {
  /*  border: 1px solid #efefef;  */
  top: -14px;
  position: relative;
  background: #fff;
  border-bottom: none !important;
  border-top: none !important;
  display: block;
  font-size: 14px;
  color: #434955;
  border-radius: 12px;
  padding: 0;
  border-radius: 12px 12px 0 0;
  /*  margin: 0 -1px !important;  */
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion-content.active {
  max-height: 10000px;
  transition: max-height 0.3s ease-in-out;
}

/* Header
=================================*/
.accordion-header {
  height: 85px;
  padding: 0 15px;
  line-height: 80px;
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  background: #888888;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  margin-top: -10px !important;
  border-radius: 12px 12px 0 0 !important;
  min-height: 40px;
  letter-spacing: 2px;
  position: relative;
  transition: background .2s linear;
}

.accordion-header-title {
  text-transform: uppercase;
  display: inline;
  margin-left: 15px;
  font-size: 90%;
}

.accordion-item:last-child .accordion-header {
  border-bottom: none;
}

.accordion-header:hover,
.accordion-header {
  background-color: #939393;
}

/* Header icons (Font Awesome) */
.accordion-header i {
  width: 26px;
  text-align: left;
  font-size: 16px;
}

/* Header arrows */
.accordion-header .accordion-item-arrow {
  position: absolute;
  right: 15px;
  height: 20px;
  margin-top: -10px;
  top: 15%;
}

.accordion-header .accordion-item-arrow:before {
  content: "\f107";
  font-family: 'FontAwesome';
  font-size: 18px;
  float: right;
  transition: all .2s linear;
}

.accordion-item.active .accordion-header .accordion-item-arrow:before {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-item:last-child .accordion-header {
  border-radius: 12px !important;
}

/* Content
=================================*/

.imageItem {
  margin-bottom: 20px;
}

.accordion-item:last-child .accordion-content {
  border-bottom: none;
  border-top: solid 1px #e9e9e9;
}

.footer {
  font-size: 16px;
  padding: 15px;
}

.footer-icon {
  font-size: 16px;
  padding: 5px;
  display: inline;
}

.footer-text {
  font-size: 80%;
}

.hidden {
  display: none;
}

.fullwidthimage {
  width: 100%;
}

.rounded {
  border-radius: 12px 12px 0 0;
  /*   margin-top: -2px; */
}

.btn-block {
  display: block;
  width: 100%;
}


.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 8px;
  text-transform: uppercase;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #333;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 8px;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* Sermon Notes
=================================*/

.sermonTitle {
  margin: 20px 0 0 0;
}

.speaker {
  margin-bottom: 20px;
}

.message-section {
  padding: 30px 20px;
}

.even {
  background: #efefef;
}

.odd {
  background: #ffffff;
}

.message-section h3 {
  margin: 10px 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #efefef;
  background-image: none;
  border: none;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.even .form-control {
  background-color: #fff;
}

.form-control.note {
  overflow: hidden;
  word-wrap: break-word;
  resize: vertical;
  height: 52px;
  margin-top: 10px;
}

#success-message,
#error-message {
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  opacity: 0;
  transition: opacity 0.5s ease, padding 0.5s ease;
}

#success-message {
  color: green;
}

#error-message {
  color: red;
}

.animated {
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  z-index: 2;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -38px;
}

.border-top {
  border-top: solid #efefef 2px;
  margin-top: 10px;
  padding-top: 0;
}

.pointer {
  cursor: pointer;
}
